<template>
    <v-container class="pb-0"> 
        <v-card elevation="2" color="step-header ml_blue" width="100%" v-bind:style="{ backgroundImage: 'url(' + headingBackgroundSVG + ')' }" v-if="stepDetails">
            <v-card-title class="white--text" v-if="stepDetails.currentStep && stepDetails.totalSteps"> 
                Step {{stepDetails.currentStep}} of {{stepDetails.totalSteps}}
            </v-card-title>
            <v-card-text class="white--text">
                <v-row class="d-flex align-end">
                    <v-col :lg="contactInfo !== null ? '6':'12'" cols="contactInfo !== null ? '12':'12'">
                        <h1 tabindex="-1" ref="stepMainHeading">
                            {{stepDetails.stepHeading}}
                        </h1>
                        <h1 role="none">
                            <small class="d-block pt-3 font-weight-light">{{stepDetails.stepSubHeading}}</small>
                        </h1>
                    </v-col>
                    <v-col lg="6" cols="12" v-if="contactInfo !== null" class="text-lg-right text-left pt-0" >
                        <h4 role="none" id="partner-phone-contact" class="contact-banner-partner font-weight-regular text-lg-right text-left" >
                            Need help or looking for more plan options? Call:
                            <v-icon class="white--text mx-1">mdi-headset</v-icon>
                            <a aria-labelledby="partner-phone-contact" class="white--text " :href="'tel:'+contactInfo" v-on:click="trackAdobeEvent()">   {{this.getNumbertoAlphanumberic(contactInfo)}}</a>
                        </h4>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="pa-0">
                <v-progress-linear ref="progressBar" height="10" :aria-valuetext="'Step ' + stepDetails.currentStep" :aria-label=" 'Step ' + stepDetails.currentStep +' of '+ stepDetails.totalSteps +': '+ stepDetails.stepHeading +' '+ Math.floor(100 * (stepDetails.currentStep / stepDetails.totalSteps)) +'%'" background-color="grey lighten-2" color="gradient" :value="Math.floor(100 * (stepDetails.currentStep / stepDetails.totalSteps))" v-if="stepDetails.currentStep && stepDetails.totalSteps"></v-progress-linear>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>
export default {
    name: 'stepHeader',
    props: {
        stepDetails: null,
        contactInfo: {
                type: String,
                default: null
        }
    },
    data: () => {
        return{
            headingBackgroundSVG :  require ('@/assets/svg/stepHeader.svg'),
        }
    },
    methods: {
        calculatePercentage: function (steps, current) {
            return (current * 100) / steps; 
        },
       
        focus() {
            const interval = setInterval(()=>{
                if(this.$refs.stepMainHeading) {
                    this.$nextTick(()=>this.$refs.stepMainHeading.focus());
                    clearInterval(interval);
                }
            },50);
        },
        trackAdobeEvent() {
            let ldo = {
                eventName: 'linkClick',
                attributes: {
                    application: 'MLPI',
                    form: 'Pet Insurance Quote',
                    linkName: this.contactInfo,
                    leadId: this.leadId
                }
            };
            this.$store.dispatch("trackAnalyticsEvent", {
                eventType: 'linkClick',
                ldo: ldo
            });
        }
    },
    mounted() {
    },
    updated() {
    }   
}
</script>