<template>
    <v-container class="pa-0">
        <v-card width="100%" elevation="1" rounded="0" class="summary-bar">
            <v-card-text class="pa-3">
                <v-row class="summary-bar-wrapper d-flex align-start pt-2 pb-1 px-2" role="region" aria-label="Summary" >
                    <div class="summary-bar-pet d-flex flex-row align-start pa-0" v-for="(pet,index) in pets" :key="pet.id" >
                        <Pet :key="pet.id" isPetSelectable @switchPet="switchPet" :petDetails=pet :petIndex="index" :isCurrent="!isFamilyPlan" :isEditable="true" :ref="'pet-'+index" @updatePricing="updatePricing"></Pet>
                        <div class="pet-plan-price text-h6" v-if="!isFamilyPlan && plans.length > 0">
                            <!-- ${{getMonthlyPremium(index)}}  -->
                            <div class="price-value xs">
                                <span class="currency">{{ getPriceSplit( getMonthlyPremium(index) )[0] }}</span>
                                <span class="units">{{ getPriceSplit( getMonthlyPremium(index) )[1]}}</span>
                                <span class="cents">{{ getPriceSplit( getMonthlyPremium(index) )[2]}}</span>
                            </div>
                        </div>
                        <!-- <div class="pet-plan-price text-h6" v-else></div> -->
                        <div class="pl-2 pr-2 pet-divider">
                            <v-divider role="none" vertical></v-divider>
                        </div>
                    </div>
                    <div class="button-addpet-wrapper pa-1" v-if="Object.keys(pets).length < 3 && Object.keys(pets).length !== maxPetView ">
                        <v-btn id="addPetBtn" class="pa-0 m-0 d-flex align-center" outlined color="ml_blue" elevation="0" aria-label="Add Additional Pet." @click.stop="showAddPetDialog(true)">
                            <v-icon class="pb-1" small>mdi-plus</v-icon>
                            <span>Add Pet</span>                            
                        </v-btn>
                    </div>
                    <v-spacer></v-spacer>
                    <span class="d-sr-only" aria-live="polite">{{announcementText}}</span>
                    <div class="ml-auto align-self-center pa-1 mr-2 price-total-wrapper" v-if="isFamilyPlan">
                        <div class="text-center">
                            <small class="d-block caption">Family Plan</small>
                            <div class="price-value sm">
                                <span class="currency font-weight-bold">{{calculateFamilyPlanTotal().charAt(0)}}</span>
                                <span class="units font-weight-bold">{{(calculateFamilyPlanTotal().slice(1)).split('.')[0]}}</span>
                                <span class="cents font-weight-bold">.{{(calculateFamilyPlanTotal().slice(1)).split('.')[1]}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="ml-auto align-self-center pa-1 mr-2 price-total-wrapper" v-else>
                        <div v-if="Object.keys(plans).length > 0">
                            <div class="text-center">
                                <small class="d-block caption">TOTAL</small>
                                <div class="price-value sm">
                                    <span class="currency font-weight-bold">{{ getPriceSplit( calculateTotals() )[0] }}</span>
                                    <span class="units font-weight-bold">{{ getPriceSplit( calculateTotals() )[1]}}</span>
                                    <span class="cents font-weight-bold">{{ getPriceSplit( calculateTotals() )[2]}}</span>
                                </div>

                                <!-- ${{calculateTotals()}} -->
                            </div>
                        </div>
                    </div>
                    <div>
                        <editCreatePetDialog @closeDialog="showAddPetDialog"
                                             @hasFinished="AddPetPetDialogFormFinished"
                                             :dialog="newPetDialog"
                                             :isEdit="false">
                        </editCreatePetDialog>
                    </div>
                    <div class="summary-wrapper">
                        <SummaryViewPanel :pets="pets"
                                          :plans="plans"
                                          :headers="headers"
                                          :isFamilyPlan="isFamilyPlan"
                                          :maxPetView="maxPetView"
                                          @openPetDialog="showAddPetDialog(true)"
                                          @switchPet="switchPet"></SummaryViewPanel>
                    </div>
                </v-row>

            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
    import Pet from './pet.vue';
    import editCreatePetDialog from './editCreatePetDialog.vue';
    import SummaryViewPanel from './SummaryViewPanel.vue';

    import SummaryBar from '../global/js/SummaryBar.js'
    export default {
        name: 'summaryBar',
        mixins: [SummaryBar],
        components: {
            Pet, editCreatePetDialog, SummaryViewPanel
        },
        data: function () {
            return {
                
            };
        },
        computed: {
             
        },
        props: {            
            isFamilyPlan: {
                type: Boolean,
                default: false,
            },
            pets: {
                type: Array
            },
            plans: {
                type: Array
            },
            headers: {
                type: Array
            }
        },
        methods: {

        }
    }
</script>