<template>
    <div class="section-wrap">
        <div v-show="loaded">
            <noscript>
                <img src="https://ad.doubleclick.net/ddm/activity/src=1524815;type=petin0;cat=petin005;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>
            </noscript>
            <noscript>
                <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=189678795578711&ev=PetQuoteSite_AboutMyPet&noscript=1"/>
            </noscript>
            <StepHeader ref="stepHeader" :contactInfo="this.getPartnerContactInfo()" :stepDetails=step[0]></StepHeader>
            <v-container class="form pt-0">
                <v-row justify="center">
                    <v-col cols="12">
                        <v-card elevation="2" class="rounded-tr-0 rounded-tl-0 v-card-form">
                            <SummaryBar ref="summaryBar" @loadPlans="loadPlans" @switchPet="switchPet" :pets="pets" :plans="selectedPolicies" :headers="planHeaders" :isFamilyPlan="isFamilyPlan" :key="summaryBarKey"></SummaryBar>
                            <v-row justify="center">
                                <v-col cols="12">
                                    <v-card-text>
                                        <!-- <CoverageDialog :isMetGen ="isMetGen"/> -->
                                        <span ref="pricingSectionHeading" id="pricingSectionHeading" class="d-sr-only" tabindex="-1" role="heading" aria-level="2" >Pricing Plans</span>
                                        <Pricing ref="pricingCards" :announcePricingSummary="exposeTotalFamilyPricesToSR" :isCustomPlanActive="isCustomPlanActive" @renderSummaryBar="renderSummaryBar" @renderPrincingComponent="renderPricingComponent" @setIsCustomPlanActive="setIsCustomPlanActive" :key="pricingKey"/>
                                        
                                        <DotLoader :loading="loadingNewPrices" :onLoadingComplete="loadingCompleteController" />
                                        <!-- SAVE DIALOG-->
                                        <div class="text-right">
                                            <v-dialog
                                            persistent
                                            v-model="saveQuoteDialog"
                                            width="385"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                    color="primary"
                                                    text
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    class="btn-lower-case"
                                                    @click="trackSaveQuote"
                                                    >
                                                    <v-icon small class="mr-1">mdi-email</v-icon>
                                                    Save Quote
                                                    </v-btn>
                                                </template>
                                                <v-card>
                                                    <v-card-title class="d-flex justify-end pb-0">
                                                        <v-btn icon
                                                            @click="this.closeSendQuoteDialog"
                                                            aria-label="Send Quote to email">
                                                            <v-icon>mdi-close</v-icon>
                                                        </v-btn>
                                                    </v-card-title>
                                                    <v-card-text class="pb-0">
                                                        <v-row>
                                                            <v-col cols="12" class="d-flex justify-center align-center flex-column">
                                                                <div class="text-center mb-4"><v-icon class="xxxxx-large auto-height">{{ this.saveQuoteDialogSave ? this.saveQuoteDialogSaveImage : this.saveQuoteDialogLeaveImage }}</v-icon></div>
                                                                <h3 class="ml_darkergray--text mb-0">{{this.saveQuoteDialogSave ? saveQuoteDialogSaveTitle : saveQuoteDialogLeaveTitle  }}</h3>
                                                                <p class="body-2 mt-3 mb-0 text-left ml_darkergray--text" v-if="!this.saveQuoteDialogSave">Enter your email or phone number below to save your quote and come back to your quote at any time through a link.</p>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <!-- Switch for phone or email
                                                                 <v-col cols="12" class="py-0">
                                                                <v-radio-group class="mt-0" mandatory dense v-model="saveQuoteMethod">
                                                                    <v-radio label="Email" :value="1"></v-radio>
                                                                    <v-radio label="Mobile phone number (MMS)" :value="2"></v-radio>
                                                                </v-radio-group>
                                                            </v-col> -->
                                                            <v-col cols="12" class="pt-0">
                                                                <v-text-field v-model="sendQuoteToMail" type="email" :rules="existingUserRules.email" label="Email" solo outlined   v-if="this.saveQuoteMethod === 1"></v-text-field>
                                                                <!-- <v-text-field v-model="sendQuoteToPhone" type="tel"  :rules="existingUserRules.phone"  label="Phone" solo outlined v-if="this.saveQuoteMethod === 2"></v-text-field> -->
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                    <v-card-actions class="d-flex flex-column justify-center">
                                                        <v-btn 
                                                            color="primary"
                                                            @click="sendQuotetoSelected"
                                                            :loading="this.sendQuoteProcessing"
                                                            :disabled="this.sendQuoteProcessing || this.sendQuoteDone || this.sendQuoteInvalid"
                                                        >
                                                        {{this.sendQuoteDone ?  this.saveQuoteDialogTextSubmitted : this.saveQuoteDialogTextSubmit}}
                                                    </v-btn>
                                                        <div v-if="this.sendQuoteError" class="v-messages theme--light error--text pt-2" role="alert">
                                                            <div class="v-messages__wrapper">
                                                                <div  class="v-messages__message"><span id="missingPetBreedMessage">{{ sendQuoteErrorText }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <v-btn
                                                            color="primary"
                                                            text
                                                            :disabled="this.sendQuoteProcessing"
                                                            @click="this.closeSendQuoteDialog"
                                                        >
                                                            {{ this.sendQuoteDone ? saveQuoteDialogTextDone : saveQuoteDialogTextCancel }}{{ this.sentQuoteTimer ?  '(' + this.sentQuoteTimer + ')' :  ''}}
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                        </div>
                                        <!-- CUSTOMIZED SECTION -->
                                        <div role="region" aria-label="Customization">
                                        <v-container>
                                            <v-row role="heading" aria-level="2">
                                                <v-col cols="12" class="text-center pt-8">
                                                    <v-btn ref="btnToggleCustomization" outlined color="ml_blue" v-on:click="toggleCustomization()" :disabled="showCustomization">
                                                        <v-icon small class="mr-1 pb-1">mdi-pencil</v-icon>{{btnToggleCustomization}}
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                            <v-row v-if="this.getPartnerContactInfo() !== null">
                                                <v-col class="">
                                                    <h4 role="none" id="partner-phone-direct" class="text-center contact-banner-partner font-weight-regular d-flex align-center justify-center">
                                                        Need help or looking for more plan options? Call:
                                                        <v-icon color="ml_darkblue" class="mx-1">mdi-headset</v-icon>
                                                        <a aria-labelledby="partner-phone-direct" class=" d-flex align-center" :href="'tel:'+getPartnerContactInfo()" v-on:click="trackAdobeEvent()">   {{this.getNumbertoAlphanumberic(getPartnerContactInfo())}}</a>
                                                    </h4>
                                                </v-col>
                                            </v-row>
                                            <v-expand-transition>
                                                <v-card class="mt-2" :elevation=" isCustomPlanActive ? 10 : 2" :class=" isCustomPlanActive ? 'v-plan-active' : '' " width="100%" v-if="showCustomization" :loading="loadingCustomPrice">
                                                    <v-card-title class="d-flex justify-space-between flex-nowrap align-center">
                                                        <!-- "btnCloseCustomization" -->
                                                        <h5 ref="customizationHeadingFocus" id="customization-heading-expandable" role="heading" aria-level="3" tabindex="-1">{{customizationHeading}}</h5>
                                                        <v-btn icon color="black" v-on:click="toggleCustomization()" class="align-self-md-end" aria-label="Close Plan Customization">
                                                            <v-icon>mdi-close</v-icon>
                                                        </v-btn>
                                                    </v-card-title>
                                                    <v-card-text fluid>
                                                        <v-row class="d-flex align-stretch">
                                                            <v-col cols="12" md="9">
                                                                <v-row role="tablist" aria-labelledby="customization-heading-expandable">
                                                                    <v-col aria-live="polite" role="none" v-for="(customization, customizationIndex) in customizations" :key="customization.name" class="py-0 " cols="6" md="3">
                                                                        <div class="d-sr-only" aria-hidden="true" role="none">
                                                                            <v-text-field readonly
                                                                            :ref="'source-'+customizationIndex"
                                                                            :id="'source-'+customizationIndex"
                                                                            :label="customization.name"
                                                                            :value="customization.sliderValues[customization.value]"
                                                                            :prefix="customization.valuePrefix"
                                                                            :suffix="customization.valueSuffix"
                                                                            :name="customization.name"
                                                                            tabindex="-1"
                                                                            ></v-text-field>
                                                                        </div>
                                                                        <div
                                                                            :ref="'custom-tab-' + customizationIndex"
                                                                            :id="'custom-tab-' + customizationIndex"
                                                                            role="tab"
                                                                            :aria-selected="activeCustomTab === customizationIndex ? 'true' : 'false'"
                                                                            :tabindex="activeCustomTab === customizationIndex ? '0' : '-1'"
                                                                            @keydown="handleArrowNavigation"
                                                                            @click="handleTabClick"
                                                                            :data-index="customizationIndex"
                                                                            class="custom-tab"
                                                                        >
                                                                            <div class="feature-name v-label">
                                                                                <span>{{customization.name}}</span>
                                                                            </div>
                                                                            <div class="feature-value"> 
                                                                                <span class="feature-value-addon" v-if="customization.valuePrefix">{{customization.valuePrefix}}</span>
                                                                                {{customization.sliderValues[customization.value]}}
                                                                                <span class="feature-value-addon" v-if="customization.valueSuffix">{{customization.valueSuffix}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row v-for="(customization, customizationControlIndex) in customizations" :key="customization.name" >
                                                                    <v-col cols="12" class="py-0">
                                                                        <v-fade-transition hide-on-leave>
                                                                            <v-card width="100%"
                                                                                    outlined
                                                                                    rounded
                                                                                    color="white"
                                                                                    elevation="2"
                                                                                    :ripple="false"
                                                                                    role="tabpanel"
                                                                                    :aria-label="customization.name"
                                                                                    v-if="customPanelExpanded  && customizationControlIndex === activeCustomTab"
                                                                                    class="mb-4"
                                                                                    tabindex="-1">
                                                                                <v-card-title class="d-flex justify-space-between py-1">
                                                                                    <span role="heading" aria-level="4" :id="'panel-heading-'+customizationControlIndex" >{{customization.name}}</span>
                                                                                    <v-btn  icon color="black" v-on:click="closeCardEditor" :aria-label="'Stop Editing Value for' + customization.name " :data-index="customizationControlIndex">
                                                                                        <v-icon>mdi-chevron-up</v-icon>
                                                                                    </v-btn>
                                                                                </v-card-title>
                                                                                <v-card-subtitle>{{customization.headline}}</v-card-subtitle>
                                                                               
                                                                                
                                                                                <v-card-text>
                                                                                    <v-row>
                                                                                        <v-col cols="12" md="auto" class="d-flex flex-row " order="1">
                                                                                            <v-btn :aria-label="'Decrement value for'+customization.name"  color="primary"  @click="handleCustomizationDecrement(customizationControlIndex)" :disabled="disableCustomizedActions(customizationControlIndex)">
                                                                                                <v-icon>mdi-chevron-left</v-icon>
                                                                                            </v-btn>
                                                                                        </v-col>
                                                                                        <v-col cols="12" md="auto" class="d-flex flex-row " order="3">
                                                                                            <v-btn :aria-label="'Increment value for'+customization.name" color="primary"  @click="handleCustomizationIncrement(customizationControlIndex)" :disabled="disableCustomizedActions(customizationControlIndex)">
                                                                                                <v-icon>mdi-chevron-right</v-icon>
                                                                                            </v-btn>
                                                                                        </v-col>
                                                                                        <v-col cols="l2" md="9" order="2">
                                                                                            <v-slider  
                                                                                                @change="updateCustomizedPricing()"
                                                                                                :ref="'control-source-'+customizationControlIndex"
                                                                                                :id="'control-source-'+customizationControlIndex"
                                                                                                :aria-labelledby="'panel-heading-'+customizationControlIndex"
                                                                                                data-store="null"
                                                                                                v-model="customization.value"
                                                                                                :tick-labels="customization.sliderValues"
                                                                                                :aria-valuemin="getAccessibleMinValue(customizationControlIndex)"
                                                                                                :min="customization.minValue"
                                                                                                :aria-valuemax="getAccessibleMaxValue(customizationControlIndex)"
                                                                                                :max="customization.maxValue"
                                                                                                :step="customization.stepValue"
                                                                                                ticks="always"
                                                                                                :tick-size="customization.sliderValues.length"
                                                                                                thumb-label="true"
                                                                                                :aria-valuenow="getAccessibleSliderValueText(customizationControlIndex)"
                                                                                                :aria-valuetext="getAccessibleSliderValueText(customizationControlIndex)"
                                                                                                :value="customization.value"
                                                                                                tabindex="0"
                                                                                                :disabled="disableCustomizedActions(customizationControlIndex)"
                                                                                            >
                                                                                            </v-slider>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                </v-card-text>
                                                                            </v-card>
                                                                        </v-fade-transition>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                            <v-col cols="12" md="3" class="mb-3 d-flex flex-column" aria-live="polite">
                                                                <v-fade-transition>
                                                                    <div v-if="!loadingCustomPrice">
                                                                        <v-row>
                                                                            <v-col class="text-center">
                                                                                <div class="price-value md">
                                                                                    <span class="currency">{{ getPriceSplit(customizedPlanMonthlyPrice)[0] }}</span>
                                                                                    <span class="units">{{ getPriceSplit(customizedPlanMonthlyPrice)[1]}}</span>
                                                                                    <span class="cents">{{ getPriceSplit(customizedPlanMonthlyPrice)[2]}}</span>
                                                                                </div>
                                                                                <small class="frequency d-block">Per Month</small>
                                                                            </v-col>
                                                                            <!-- <v-col class="text-center">
                                                                                <div class="price-value md">
                                                                                    <span class="currency">{{customizedPlanFirstMonthPrice[0]}}</span>
                                                                                    <span class="units">{{customizedPlanFirstMonthPrice[1]}}</span>
                                                                                    <span class="cents">{{customizedPlanFirstMonthPrice[2]}}</span>
                                                                                </div>
                                                                                <small class="frequency d-block">First Month</small>

                                                                                <h2>
                                                                                    {{customizedPlanFirstMonthPrice}}
                                                                                    <small class="d-block">First Month</small>
                                                                                </h2>
                                                                            </v-col> -->
                                                                        </v-row>
                                                                        <v-row>
                                                                            <v-col class="text-center py-0">
                                                                                <v-divider role="none" ></v-divider>
                                                                                <p>Your Rate</p>
                                                                            </v-col>
                                                                        </v-row>
                                                                        <v-row>
                                                                            <v-col class="text-center">
                                                                                <v-btn class="btn-wrap" :color="isCustomPlanActive ? 'ml_mediumgray' : 'primary' " dark v-on:click=" handleCustomizedPlanSelected()" :aria-disabled="isCustomPlanActive ? 'true' : 'false'" :tabindex="isCustomPlanActive ? -1 : 0" >
                                                                                    <span v-if="isCustomPlanActive"> {{btnSelectedCustomPlanText}}</span>
                                                                                    <span v-else> {{btnSelectCustomPlanText}}</span>
                                                                                    <span class="d-sr-only"> &nbsp;for: ${{customizedPlanMonthlyPrice}}</span>
                                                                                </v-btn>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </div>

                                                                    <DotLoader :loading="loadingCustomPrice" :loaderText="this.customPlanLoadText" />
                                                                </v-fade-transition>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </v-expand-transition>
                                        </v-container>
                                        </div>
                                        <!-- Preventive Care Section -->
                                
                                        <v-expand-transition>
                                            <div v-if="showRoutineCare">
                                                <RoutineCare ref="routineCare" @renderSummaryBar="renderSummaryBar" :routinePlanConfig="routinePlanConfig" :riderOptions="riderOptions" :announcePriceCardChange="handleRoutineCareClick" @updateRoutineCare="updateRoutineCare($event)" />
                                            </div>
                                        </v-expand-transition>
                                
                                        <!-- FAMILY PLAN SECTION -->
                                        <v-expand-transition>
                                            <v-container v-if="pets.length > 1 && showFamilyPlan && offerFamilyPlan(pets, partner.isAgeRestrictionExempted)">
                                                <v-card role="region" aria-label="Switch To Family">
                                                    <v-card-text>
                                                        <v-row class="d-flex justify-space-between align-center flex-wrap">
                                                            <v-col cols="12" md="8">
                                                                <div class="d-flex align-center">
                                                                    <inline-svg id="familyPlanSVG" :src="familyPlanSVG" height="75" width="75" class="mr-2" role="none"></inline-svg>
                                                                    <div class="">
                                                                        <h3 role="heading" aria-level="2">
                                                                        <span>Since you have multiple Pets we'd like to offer you a Family Plan  </span> 
                                                                        <a aria-label="Go to Footnote 5 on Family Plans" id="anchorFootnote5" @click='goToElementAndFocus("footnote-5")' href="#footnote-5"><sup>[5]</sup></a>
                                                                        </h3>
                                                                        <!-- <v-btn class="pa-0" color="primary" text>Learn More</v-btn> -->
                                                                    </div>
                                                                </div>
                                                            </v-col>
                                                            <v-spacer></v-spacer>
                                                            <v-col cols="12" md="4" class="text-center">
                                                                <span class="d-sr-only" id="IndividualPlanChanges" aria-hidden="true">Selecting the Plan will accordingly affect the pricings in the above price section.</span>
                                                                <v-btn aria-describedby="IndividualPlanChanges" ref="showFamilyPlanBtn" color="primary" v-on:click="handleShowFamilyPlanClick()">Show Family Plans</v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </v-container>
                                        </v-expand-transition>
                                        

                                        <!-- FAMILY SECTION UNDER CUSTOMIZE -->
                                        <v-container v-if="pets.length > 1 && !showFamilyPlan">
                                            <v-card role="region" aria-label="Switch To Individual">
                                                <v-card-text>
                                                    <v-row class="d-flex justify-space-between align-center">
                                                        <v-col cols="auto" md="auto">
                                                            <inline-svg id="familyPlanSVG" :src="familyPlanSVG" height="75" width="75" class="mr-2" role="none"></inline-svg>
                                                        </v-col>
                                                        <v-col cols="12" md="6">
                                                            <h3 role="heading" aria-level="2">Decided a family plan is not for you? No problem! Go back to individual plan here.</h3>
                                                        </v-col>
                                                        <v-spacer></v-spacer>
                                                        <v-col cols="6" md="auto" class="text-right">
                                                            <span class="d-sr-only" id="FamilyPlanChanges" aria-hidden="true">Selecting the Plan will accordingly affect the pricings in the above price section.</span>
                                                            <v-btn aria-describedby="FamilyPlanChanges" ref="showIndividualPlanBtn" color="primary" v-on:click="handleShowIndividualPlanClick()">Show Individual Plans</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-container>
                                        
                                        <!-- DISCOUNTS -->
                                        <div role="region" aria-label="Discounts Applied">
                                        <v-container>
                                            <v-row justify="center" v-if="Object.keys(discounts).length != 0">
                                                <v-col cols="12">
                                                    <v-card elevation="0" width="auto">
                                                        <v-card-title class="px-0" > <span id="Discount-Title" role="heading" aria-level="2">Discounts Applied </span> <a aria-label="Go to Footnote 3 on Discounts." id="anchorFootnote3" href="#footnote-3"><sup>[3]</sup></a>  </v-card-title>
                                                        <v-card-text class="pa-0">
                                                            <v-row class="d-flex flex-row align-stretch justify-space-around">
                                                                <span class="d-sr-only" id="CheckboxState" aria-hidden="true">Checking or unChecking the check box will affect the pricing in the pricing list above</span>
                                                                <v-col cols="12" md="4" sm="6" v-for="discount in discounts" :key="discount.discountId">

                                                                    <v-card height="100%" elevation="3" class="v-card-border-gradient">
                                                                       
                                                                        <v-card-text class="d-flex flex-column justify-start pa-6">
                                                                            <div class="d-flex flex-row align-center justify-space-between mt-2 mb-3">
                                                                                <v-icon>$discountIcon</v-icon>
                                                                                <div class="fake_checkbox_wrapper">
                                                                                    <input type="checkbox" class="transparent_checkbox" :id="'discount-chk-native-' + discount.discountId.toString()" :aria-labelledby="'Discount-Title Checkbox-title-'+discount.discountId" :aria-describedby="'Checkbox-description-'+discount.discountId+' CheckboxState'" :checked="getDiscountValue(discount.discountId) ?'checked':null" @change="handleInputClick(discount.discountId)"/>
                                                                                
                                                                                    <v-checkbox :aria-describedby="'Checkbox-description-'+discount.discountId+' CheckboxState'" 
                                                                                    :ref="'discount-chk-' + discount.discountId.toString()" 
                                                                                    :id="'discount-chk-' + discount.discountId.toString()" 
                                                                                    :aria-labelledby="'Discount-Title Checkbox-title-'+discount.discountId" 
                                                                                    :input-value="getDiscountValue(discount.discountId)" 
                                                                                    v-on:change="updateDiscounts($event,discount.discountId)"
                                                                                    hide-details
                                                                                    class="mt-0"
                                                                                    tabindex="-1"
                                                                                    aria-hidden="true"
                                                                                    ></v-checkbox>
                                                                            </div>
                                                                            </div>
                                                                            <label :for="'discount-chk-' + discount.discountId.toString()" class="discount-details">
                                                                                <h4 role="none" class="mb-2" :id="'Checkbox-title-'+discount.discountId">{{discount.name}}</h4>
                                                                                <span  :id="'Checkbox-description-'+discount.discountId">{{discount.description}} </span>
                                                                            </label>
                                                                        </v-card-text>
                                                                    </v-card>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                        </div>
                                        <!-- COVERAGE INLINE-->
                                        <CoverageInline :isMetGen ="isMetGen"/>
                                        <!-- TERMS DEFINITIONS-->
                                        <div role="region" aria-label="Terms Definition" data-source="" @blur="goToElementWithFocusCallback()">
                                            
                                            <v-container >
                                                <v-row>
                                                    <v-col class="my-6" cols="12">
                                                        <v-divider role="none" ></v-divider>
                                                    </v-col>
                                                    <v-col cols="12" class="d-flex flex-row align-center pb-1">
                                                        <span class="d-sr-only" id="TermsDefinitions" ref="TermsDefinitions" tabindex="-1" role="heading" aria-level="2" >Terms Definitions</span>
                                                        <v-row>
                                                            <v-col  class="d-flex align-start" cols="12" md="3">
                                                                <v-icon color="secondary">mdi-check</v-icon> <h4 role="heading" aria-level="3"> What is annual benefit?</h4>
                                                            </v-col>
                                                            <v-col cols="12" md="9">
                                                                <p class="mb-0">This is the maximum amount of reimbursement per policy year. This amount does not include the deductible and the reimbursement amount.</p>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                    <v-col cols="12" class="d-flex flex-row align-center pb-1">
                                                        <v-row>
                                                            <v-col  class="d-flex align-start" cols="12" md="3">
                                                                <v-icon color="secondary">mdi-check</v-icon> <h4 role="heading" aria-level="3"> What is reimbursement? </h4>
                                                            </v-col>
                                                            <v-col cols="12" md="9">
                                                                <p class="mb-0">This is the percentage of the covered allowable charges for which MetLife is responsible per policy year.</p>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                    <v-col cols="12" class="d-flex flex-row align-center pb-1">
                                                        <v-row>
                                                            <v-col class="d-flex align-start" cols="12" md="3">
                                                                <v-icon color="secondary">mdi-check</v-icon><h4 role="heading" aria-level="3"> What is a deductible?</h4>
                                                            </v-col>
                                                            <v-col cols="12" md="9">
                                                                <p class="mb-0">This is the specified amount of money that the pet parent must pay before MetLife will pay a claim.</p>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                    <v-col cols="12" class="d-flex flex-row align-center pb-1">
                                                        <v-row>
                                                            <v-col  class="d-flex align-start" cols="12" md="3">
                                                                <v-icon color="secondary">mdi-check</v-icon> <h4 role="heading" aria-level="3"> What is preventive care?</h4>
                                                            </v-col>
                                                            <v-col cols="12" md="9">
                                                                <p class="mb-0">Any treatment, service or procedure for the purpose of prevention of injury or illness or for promotion of general health, where there has been no injury or illness.</p>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                                <v-btn text class="btn-text-reset btn-text-underline" href="#pricingSectionHeading" >
                                                    Jump Back To Pricing Section
                                                </v-btn>
                                            </v-container>
                                        </div>
                                    </v-card-text>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
            <v-footer ref="controlZoomLevel" app color="white" elevation="2">
                <v-row class="d-flex justify-center align-center">
                    <v-col cols="auto">
                        <v-btn v-on:click="goBack()">{{previousButtonText}}</v-btn>
                    </v-col>
                    <!-- <v-col cols="auto">
                        <v-btn v-on:click="setMaxPet( 3 )">multipet</v-btn>
                    </v-col> -->
                    <v-col cols="auto">
                        <v-btn color="primary" v-on:click="goToCheckout()">
                            {{nextButtonText}}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-footer>
        </div>
        <DotLoader :isAbsolute="false" :loading="!loaded" :onLoadingComplete="loadingCompleteController"/>
    </div>
</template>
<script>
    import StepHeader from '@/components/shared/StepHeader.vue';
    import SummaryBar from '@/components/shared/SummaryBar.vue';
    // import CoverageDialog from '../components/shared/CoverageDialog.vue';
    import CoverageInline from '@/components/shared/CoverageInline.vue';
    import Pricing from '@/components/Pricing.vue';
    import Summary from '@/components/Summary';
    import RoutineCare from '@/components/RoutineCare';
    import Swal from 'sweetalert2';
    import DotLoader from '@/components/shared/DotLoader.vue';
    import InlineSvg from 'vue-inline-svg';
    import validator from 'validator';

    import pricingPage from '../Global/JS/pricingPage.js';

    export default {
        mixins: [pricingPage],
        components: { Pricing, CoverageInline, Summary, RoutineCare, StepHeader, SummaryBar, DotLoader, InlineSvg },
        data: () => ({
            
        }),
        watch: {
            
        },
        created() {
            
        },
        mounted() {
            window.addEventListener("resize",(e) => {
                let zoomLevel = Math.round(((window.outerWidth) / window.innerWidth) * 100 + 1);
                if(zoomLevel >= 200) {
                    setTimeout(() => {
                        this.handleZoomControl();
                        this.handleAddPositionClasses();
                    }, 100);
                } else {
                    this.retainBackZoomControlClasses();

                    this.handleRemovePositionClasses();
                }
            });

            if(this.currentQuoteFunnel !== null && this.currentQuoteFunnel.PricingHeader !== null){
                this.step[0].stepHeading = this.currentQuoteFunnel.PricingHeader;
            }
            if(this.currentQuoteFunnel !== null && this.currentQuoteFunnel.PricingSubHeader !== null){
                this.step[0].stepSubHeading = this.currentQuoteFunnel.PricingSubHeader;
            }

            if( this.getPartnerContactInfo() !== null ){
                this.step[0].stepHeading = "What does your pet need covered?";
                this.step[0].stepSubHeading = "Let's  build a plan that fits you and your best friend.";
            }            
        },
        computed: {
            nextButtonText: function () {
                if (this.nextPetIndex != null) {
                    return this.nextPetIndex === this.currentPetIndex ? `SELECT PLAN FOR ${this.pets[this.nextPetIndex].name}` : `NEXT: SELECT PLAN FOR ${this.pets[this.nextPetIndex].name}`;
                } else {
                    return "Go to: Checkout";
                }
            },
        },
        updated(){
            
        },
        methods: {
            goBack() {
                if (this.currentPetIndex === 0) {
                    this.$store.commit("setCurrentPage","Pet Info");
                    this.redirectToView(this.pageName);
                } else {
                    var newPetIndex = this.currentPetIndex - 1;
                    this.$store.commit("setCurrentPetIndex", newPetIndex);
                    this.$store.commit("setCurrentPet", this.pets[newPetIndex]);
                    this.$store.commit("setCurrentPlans", this.getFastQuoteResponse.fastQuotes.petFastQuotes[newPetIndex].fastQuotes);
                }
            },
            handleShowFamilyPlanClick() {
                this.$store.commit("setCurrentPetIndex", 0);
                this.$store.commit("setSelectedPolicies", []);

                this.showFamilyPlans();
                setTimeout(() => {
                    this.$refs.showIndividualPlanBtn.$el.focus();
                    this.$refs.pricingCards.exposePricingCardChangesToSR();
                }, 500);
            },
            showFamilyPlans() {
                this.customizedPlanValues.limit = this.getFastQuoteResponse.limitOptions[0];
                this.customizedPlanValues.deductible = this.getFastQuoteResponse.defaultDeductible;
                this.customizedPlanValues.reimbursement = this.getFastQuoteResponse.defaultReimbursement;
                this.customizedPlanValues.rider = this.getFastQuoteResponse.defaultRider.id;
                this.customizedPlanMonthlyPrice = this.familyPlans[0].monthlyPremium;
                this.customizedPlanFirstMonthPrice = this.familyPlans[0].firstMonthPremium;
                this.currentCustomizedPlan = this.familyPlans[0];

                this.updateOptions();

                this.$store.commit("setCurrentPlans", this.familyPlans);

                this.showFamilyPlan = false;
                this.$store.commit("setViewingFamilyPlan", true);
                this.$store.commit("setIsFamilyPlan", true);

                this.renderSummaryBar();
                this.hideCustomizedPlan();
            },
            async handleShowIndividualPlanClick() {
                await this.$store.commit("setCurrentPetIndex", 0);
                this.$store.commit("updatePet", this.$store.state.pets[this.$store.state.currentPetIndex]);
                this.$store.commit("setSelectedPolicies", []);

                this.showIndividualPlans();
                setTimeout(() => {
                    this.$refs.showFamilyPlanBtn.$el.focus();
                    this.$refs.pricingCards.exposePricingCardChangesToSR();
                }, 500);
            },
            showIndividualPlans() {
                const pet = this.getFastQuoteResponse.fastQuotes.petFastQuotes[this.currentPetIndex];
                
                const monthlyPremium = pet.fastQuotes[this.currentPetIndex]
                    ? pet.fastQuotes[this.currentPetIndex].monthlyPremium
                    : pet.fastQuotes[0].monthlyPremium;

                const firstMonthPremium = pet.fastQuotes[this.currentPetIndex]
                    ? pet.fastQuotes[this.currentPetIndex].firstMonthPremium
                    : pet.fastQuotes[0].firstMonthPremium;

                this.customizedPlanValues.limit = this.getFastQuoteResponse.fastQuotes.petFastQuotes[this.currentPetIndex].limitOptions[0];
                this.customizedPlanValues.deductible = this.getFastQuoteResponse.fastQuotes.petFastQuotes[this.currentPetIndex].defaultDeductible;
                this.customizedPlanValues.reimbursement = this.getFastQuoteResponse.fastQuotes.petFastQuotes[this.currentPetIndex].defaultReimbursement;
                this.customizedPlanValues.rider = this.getFastQuoteResponse.fastQuotes.petFastQuotes[this.currentPetIndex].defaultRider.id;
                this.customizedPlanMonthlyPrice = monthlyPremium;
                this.customizedPlanFirstMonthPrice = firstMonthPremium;
                this.currentCustomizedPlan = this.allPlans[this.currentPetIndex].fastQuotes[0];

                this.updateOptionsIndividualPet();

                this.$store.commit("setCurrentPlans", this.getFastQuoteResponse.fastQuotes.petFastQuotes[this.currentPetIndex].fastQuotes);
                this.showFamilyPlan = true;
                this.$store.commit("setViewingFamilyPlan", false);
                this.$store.commit("setIsFamilyPlan", false);
                this.renderSummaryBar();
                this.hideCustomizedPlan();
            },
        }
    }
</script>
<style>
    #TermsDefinitions{
        transform: translateY(calc(((100vh - 250px) / 2) * -1));
    }
    span.d-sr-only:has(>input[type=checkbox]:focus)+div:has(input[aria-checked]){
        outline: 2px solid #007abc !important;
    }
    .btn-position {
        position:unset  !important;
    }
</style>