export default {
    // Careful | in cases where the mixin and the component define 
    // the same property, the component's property will take precedence.
    data() {
        return {
            newPetDialog: false,
            announcementText:""
        }
    },
    computed: {
        currentPetIndex() {
            return this.$store.getters.currentPetIndex;
        },
        maxPetView() {
            return this.$store.getters.maxPetView;
        },
        leadId(){
            return this.$store.getters.leadId;
        }, 
    },
    methods: {
        announceTotalFamilyPrices(announcePriceSummary) {
            if(announcePriceSummary) {
                if(this.isFamilyPlan) {
                    this.announcementText = "Based on your selection, now your family plan price is:" + this.calculateFamilyPlanTotal();
                } else {
                    if(Object.keys(this.plans).length > 0) {
                        this.announcementText = ("Based on your selection, now your total plan price is:" + this.getPriceSplit(this.calculateTotals())[0]+""+this.getPriceSplit(this.calculateTotals())[1]+""+this.getPriceSplit(this.calculateTotals())[2]);
                    }
                }
            } else {
                this.announcementText = "";
            }
        },
        getMonthlyPremium(index) {
            let selectPlanIndex = this.plans.findIndex(p => p.petArrayIndex === index);
            if (selectPlanIndex !== -1) {
                return this.plans[selectPlanIndex].planDetails.monthlyPremium;
            }
            return 0.00;
        },
        calculateTotals: function () {
            let total = [];

            if (this.plans.length > 0) {
                Object.entries(this.plans).forEach(([key, val]) => {
                    total.push(parseFloat(val.planDetails.monthlyPremium))
                });
            }

            //TODO: this function would work if we get a number instead of a string $ ##.## /mo > data needs to change or received in actual integer
            return total.reduce(function (total, num) { return total + num }, 0);
        },
        calculateFamilyPlanTotal: function () {
            let result = 0;

            if (this.plans.length > 0) {
                result = this.plans[0].planDetails.monthlyPremium;
            }

            return this.formatCurrencyValue(result);
        },
        showAddPetDialog(status) {
            if (!status) {
                this.$store.commit("setCurrentPetIndex", 0);
            } else {
                let ldo = {
                eventName: 'linkClick',
                    attributes: {
                        application: 'MLPI',
                        form: 'Pet Insurance Quote',
                        linkName: 'Add Pet',
                        leadId: this.leadId,
                        stepName: 'Plan Selection'
                    }
                };
                digitalData.eventTrack('linkClick', ldo);
            }

            this.newPetDialog = status;
            this.$store.commit("clearCurrentPet");
        },
        updatePricing() {
            this.$emit("loadPlans");
        },
        AddPetPetDialogFormFinished() {
            this.updatePricing();
        },
        formatCurrencyValue(value) {
            return new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD"
            }).format(value);
        },
        switchPet() {
            this.$emit("switchPet");
        }
    }
}