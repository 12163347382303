<template>
    <v-container>
        <v-row class="d-flex align-center summary-row">
            <v-col cols="12" md="9" class="summary-table-wrapper ">
                <table class="summary-table table-alternate" width="100%" cellpadding="0" cellspacing="0">
                    <thead>
                        <tr v-if="!isFamilyPlan">
                            <th scope="col" role="columnheader">
                                <span class="d-sr-only">Plan Parameters</span>
                            </th>
                            <th scope="col" role="columnheader" class="summary-td-header" v-for="pet in pets" :key="pet.name">
                                <PetView class="mb-auto" :petDetails=pet :isVertical="true"></PetView>
                            </th>
                            <td scope="col" role="columnheader" v-if="Object.keys(pets).length < 3  && maxPetView >= 3">
                                <div class="d-flex flex-column text-center">
                                    <span>You can add up to {{maxPetView}} pets</span>
                                    <small>Click add pet bellow</small>
                                </div>
                            </td>
                            <th scope="col" role="columnheader" v-if="Object.keys(pets).length < 2  && maxPetView >= 2">
                                <div class="d-flex flex-column text-center">
                                    <span>You can add up to {{maxPetView}} pets</span>
                                    <small>Click add pet bellow</small>
                                </div>
                            </th>
                            <th scope="col" role="columnheader" v-if="Object.keys(pets).length < 1  && maxPetView >= 1">
                                <div class="d-flex flex-column text-center">
                                    <span>You can add up to {{maxPetView}} pets</span>
                                    <small>Click add pet bellow</small>
                                </div>
                            </th>
                        </tr>
                        <!-- Family Plan -->
                        <tr v-else>
                            <th scope="row" role="rowheader" >
                                <h3>Family Plan for: </h3></th>
                            <th scope="col" role="columnheader">
                                <div class="d-flex flex-row justify-center">
                                    <PetView :petDetails=pet :isVertical="true" :isCurrent="false" v-for="pet in pets" :key="pet.name"></PetView>
                                </div>
                            </th>
                            <th scope="col" role="columnheader" v-if="Object.keys(pets).length < 3 && maxPetView >= 3">
                                <div class="d-flex flex-column text-center">
                                    <span>You can add up to 3 pets</span>
                                    <small>Click add pet bellow</small>
                                </div>
                            </th>
                            <th scope="col" role="columnheader" v-if="Object.keys(pets).length < 2 && maxPetView >= 2">
                                <div class="d-flex flex-column text-center">
                                    <span>You can add up to 3 pets</span>
                                    <small>Click add pet bellow</small>
                                </div>
                            </th>
                            <th scope="col" role="columnheader" v-if="Object.keys(pets).length < 1 && maxPetView >= 1">
                                <div class="d-flex flex-column text-center">
                                    <span>You can add up to 3 pets</span>     
                                    <small>Click add pet bellow</small>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody v-if="!isFamilyPlan">
                        <tr v-for="plan in flattenArray(headers, pets)" :key="plan.Header">
                            <th scope="row" role="rowheader">
                                {{plan.Values[0]}}
                            </th>
                            <td v-if="Object.keys(plan.Values[1]).length != 0">
                                <span class="d-block text-center">
                                    {{plan.Values[1]}}
                                </span>
                            </td>
                            <td  class="table-cell-empty" v-else>
                                <span class="d-block text-center"> -<span class="d-sr-only">No data available</span>- </span>
                            </td>
                            <template v-if="maxPetView > 1">
                                <td v-if=" Object.keys(plan.Values[2]).length != 0">
                                    <span class="d-block text-center">
                                        {{plan.Values[2]}}
                                    </span>
                                </td>
                                <td class="table-cell-empty" v-else>
                                    <span class="d-block text-center"> -<span class="d-sr-only">No data available</span>- </span>
                                </td>
                            </template>
                            <template v-if="maxPetView > 2">
                                <td  v-if=" Object.keys(plan.Values[3]).length != 0">
                                    <span class="d-block text-center">
                                        {{plan.Values[3]}}
                                    </span>
                                </td>
                                <td class="table-cell-empty" v-else>
                                    <span class="d-block text-center"> -<span class="d-sr-only">No data available</span>- </span>
                                </td>
                            </template>
                        </tr>

                    </tbody>
                    <!-- Family Plan -->
                    <tbody v-else>
                        <tr v-for="plan in flattenArray(headers, pets)" :key="plan.Header">
                            <th scope="row" role="rowheader">
                                {{plan.Values[0]}}
                            </th>
                            <td v-if="Object.keys(plan.Values[1]).length != 0">
                                <span class="d-block text-center">
                                    {{plan.Values[1]}}
                                </span>
                            </td>
                            <td class="table-cell-empty" v-else>
                                <span class="d-block text-center"> -<span class="d-sr-only">No data available</span>- </span>
                            </td>
                            <td v-if="Object.keys(pets).length < 1">
                                <span class="d-block text-center"> -<span class="d-sr-only">No data available</span>- </span>
                            </td>
                            <td v-if="Object.keys(pets).length < 2">
                                <span class="d-block text-center"> -<span class="d-sr-only">No data available</span>- </span>
                            </td>
                            <td v-if="Object.keys(pets).length < 3">
                                <span class="d-block text-center"> -<span class="d-sr-only">No data available</span>- </span>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr v-if="!isFamilyPlan">
                            <th scope="row" role="rowheader"><span class="d-sr-only">Summary</span></th>
                            <td class="text-center" v-for="(pet, index) in pets" :key="index">
                                <div v-if="plans.findIndex(x => x.petArrayIndex == index) != -1">
                                    <div class="price-value sm mt-2">
                                        <span class="currency">$</span>
                                        <span class="units"> {{ plans.find(x => x.petArrayIndex == index).planDetails.monthlyPremium.toString().split(".")[0] }}</span>
                                        <span class="cents">{{ plans.find(x => x.petArrayIndex == index).planDetails.monthlyPremium.toString().split(".")[1] }}</span>
                                    </div>
                                    <div class="frequency mb-2">
                                        per month
                                    </div>
                                </div>
                                <span v-else class="d-sr-only">Ensure that you have selected a plan for this pet.</span>
                            </td>
                            <td class="text-center" v-if="Object.keys(pets).length < 2 && maxPetView >= 2">
                                <span class="d-sr-only">Please add a pet first, then select a plan to view its summary</span>
                            </td>

                            <td class="text-center" v-if="Object.keys(pets).length < 3 && maxPetView >= 3">
                                <span class="d-sr-only">Please add a pet first, then select a plan to view its summary</span>
                            </td>
                        </tr>
                        <tr v-else>
                            <th scope="row" role="rowheader"><span class="d-sr-only">Summary</span></th>
                            <td class="text-center" v-for="planCost in plans" :key="planCost.planRate">
                                <div class="price-value sm mt-2">
                                    <span class="currency">$</span>
                                    <span class="units"> {{planCost.planDetails.monthlyPremium.toString().split(".")[0]}}</span>
                                    <span class="cents">{{planCost.planDetails.monthlyPremium.toString().split(".")[1]}}</span>
                                </div>
                                <div class="frequency mb-2">
                                    per month
                                </div>
                            </td>
                            <td class="text-center" v-if="Object.keys(pets).length < 1"><span class="d-sr-only">No data available</span></td>
                            <td class="text-center" v-if="Object.keys(pets).length < 2"><span class="d-sr-only">No data available</span></td>
                            <td class="text-center" v-if="Object.keys(pets).length < 3"><span class="d-sr-only">No data available</span></td>
                                                </tr>
                        <tr v-if="!isFamilyPlan">
                            <th scope="row" role="rowheader"><span class="d-sr-only">Actions</span> </th>
                            <td class="text-center" v-for="(pet, index) in pets" :key="index">
                                <v-btn color="ml_blue" outlined v-if="plans.findIndex(x => x.petArrayIndex == index) != -1" @click="changePlan(plans.find(x => x.petArrayIndex == index))">
                                    Change Plan
                                </v-btn>
                                <span v-else  class="d-sr-only">Ensure that you have selected a plan for this pet.</span>
                            </td>

                            <td class="text-center" v-if="Object.keys(pets).length < 1 && maxPetView >= 1">
                                <v-btn color="primary" class="text--white" @click="addPet"> Add Pet</v-btn>
                            </td>
                            <td class="text-center" v-if="Object.keys(pets).length < 2 && maxPetView >= 2">
                                <v-btn color="primary" class="text--white" @click="addPet"> Add Pet</v-btn>
                            </td>
                            <td class="text-center" v-if="Object.keys(pets).length < 3  && maxPetView >= 3 ">
                                <v-btn @keydown="handleModalTabShiftLastElement" color="primary" class="text--white" @click="addPet"> Add Pet</v-btn>
                            </td>
                        </tr>
                        <tr v-else>
                            <th scope="row" role="rowheader"><span class="d-sr-only">Actions</span> </th>
                            <td class="text-center" v-for="plan in plans" :key="plan.planRate">
                                <v-btn color="ml_blue" outlined v-if=" Object.keys(plans[0]).length != 0" @click="changePlan(plan)">
                                    Change Plan
                                </v-btn>
                                <span v-else class="d-sr-only">Ensure that you have selected a plan for this pet.</span>
                            </td>
                            <td class="text-center" v-if="Object.keys(pets).length < 1">
                                <v-btn color="primary" class="text--white"> Include Pet in Family Plan</v-btn>
                            </td>
                            <td class="text-center" v-if="Object.keys(pets).length < 2">
                                <v-btn color="primary" class="text--white"> Include Pet in Family Plan</v-btn>
                            </td>
                            <td class="text-center" v-if="Object.keys(pets).length < 3">
                                <v-btn @keydown="handleModalTabShiftLastElement" color="primary" class="text--white"> Include Pet in Family Plan</v-btn>
                            </td>
                        </tr>

                    </tfoot>
                </table>
            </v-col>
            <v-col cols="12" md="3">
                <v-card class="quote-card mx-auto">
                    <v-card-title primary--text>
                        <div aria-level="3" role="heading" class="text-center">Quote Breakdown</div>
                    </v-card-title>
                    <v-card-text>
                        <div class="quote-breakdown d-flex flex-column">
                            <v-row no-gutters class="quote-section">
                                <v-col cols="4" class="quote-text"> Your Rate </v-col>
                                <v-col cols="8" class="quote-value">${{getMonthlyTotal()}} / month</v-col>
                            </v-row>
                            <v-divider role="none"></v-divider>
                            <v-row no-gutters class="quote-section">
                                <v-col cols="4" class="quote-text"> First month </v-col>
                                <v-col cols="8" class="quote-value"> ${{getFirstMonth()}} / first month </v-col>
                            </v-row>
                            <!-- <v-divider></v-divider>
                            <v-row no-gutters class="quote-section">
                                <v-col cols="4" class="quote-text"> Subtotal </v-col>
                                <v-col cols="8" class="quote-value">${{quoteTotals[0].subTotal}}</v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row no-gutters class="quote-section">
                                <v-col cols="4" class="quote-text"> Fees </v-col>
                                <v-col cols="8" class="quote-value">${{quoteTotals[0].fees}}</v-col>
                            </v-row> -->
                            <v-divider role="none"></v-divider>
                            <v-row no-gutters class="quote-total">
                                <v-col cols="4" class="quote-text"> Your Total </v-col>
                                <v-col cols="8" class="quote-value">
                                    <div class="price-value md">
                                        <span class="currency">$</span>
                                        <span class="units">{{getMonthlyTotal().toString().split(".")[0]}}</span>
                                        <span class="d-sr-only">.</span>
                                        <span class="cents">{{getMonthlyTotal().toString().split(".")[1]}}</span>
                                    </div>
                                    <div class="frequency">
                                        Monthly
                                    </div>

                                </v-col>

                            </v-row>

                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>


    </v-container>
</template>
<script>
    import PetView from './pet.vue';

    export default {
        name: 'petPlanView',
        components: {
            PetView,
        },
        props: {
            isFamilyPlan: {
                type: Boolean,
                default: false
            },
            pets: {
                type: Array
            },
            plans: {
                type: Array
            },
            headers: {
                type: Array
            },
            maxPetView: {
                type: Number,
                default: 3
            }
        },
        computed: {
            accidentDate() {
                return this.$store.getters.accidentDate;
            },
            illnessDate() {
                return this.$store.getters.illnessDate;
            },
            sortedPlans: function () {
                return this.plans.sort((a, b) => a.petArrayIndex - b.petArrayIndex);
            }
        },
        mounted (){
            // this.$refs.name.focus(); 
        },
        data: () => {
            return {
                array: null,
                petIndex: 3,
                quoteTotals: [
                    {
                        rateMonth: 0,
                        firstMonth: 0,
                        subTotal: 0,
                        fees: 0,
                        total: 0
                    }
                ]
            }
        },
        methods: {
            handleModalTabShiftLastElement(e){
                if(e.shiftKey) return;
                if(!e.shiftKey && e.key === "Tab") e.preventDefault();
            },
            flattenArray(Headers, Pets) {
                var self = this;
                let array = [];

                Object.keys(Headers[0]).forEach(key => {
                    let row = { Header: key, Values: [Headers[0][key], {}, {}, {}] }

                    Pets.forEach(function (element, index) {

                        var petPlan = self.plans.find(x => x.petArrayIndex == index);

                        if (petPlan != undefined) {
                            if (row.Header === "rider") {
                                row.Values[index + 1] = petPlan.planDetails[row.Header].displayName;
                            } else if (row.Header === "accidentalCoverageStart") {
                                row.Values[index + 1] = self.accidentDate;
                            } else if (row.Header === "illnessCoverageStart") {
                                row.Values[index + 1] = self.illnessDate;
                            } else if (row.Header === "reimbursements") {
                                let reimbursementCalc = petPlan.planDetails[row.Header] * 100;
                                row.Values[index + 1] = reimbursementCalc.toString() + "%";
                            } else if (row.Header === "monthlyPremium" || row.Header === "firstMonthPremium") {
                                row.Values[index + 1] = "$" + petPlan.planDetails[row.Header].toString();
                            } else {
                                row.Values[index + 1] = petPlan.planDetails[row.Header].toString();
                            }
                        } else {
                            row.Values[index + 1] = "";
                        }
                    });
                    array.push(row);
                });
                this.array = array; //this is just for checking the array can be deleted

                return array;
            },
            getFirstMonth() {
                var firstMonth = 0;

                this.plans.forEach(plan => {
                    let firstMonthTotal = plan.planDetails.firstMonthPremium;

                    firstMonth += firstMonthTotal;
                });

                return firstMonth.toFixed(2);
            },
            getMonthlyTotal() {
                var rateMonth = 0;

                this.plans.forEach(plan => {
                    let monthlyTotal = plan.planDetails.monthlyPremium;

                    rateMonth += monthlyTotal;
                });

                return rateMonth.toFixed(2);
            },
            changePlan(plan) {
                this.$store.commit("setCurrentPetIndex", plan.petArrayIndex);
                this.$emit("closeMenu");
                this.$emit("changePlan");
            },
            addPet() {
                this.$emit("addPet");
            }
        }
    }
</script>