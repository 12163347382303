<template>
    <div class="summary-cta">
        <v-overlay :value="menu"></v-overlay>
        <v-menu v-model="menu"
        role="none"       
        :close-on-content-click="false"
                max-width="100%"
                offset-y
                content-class="summary-view-panel container"
                elevation="0"
                transition="slide-y-transition"
                id="petDetailDialog"
                bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                class="m-0 pa-0 ml_blue--text d-flex align-center"
                text
                dark
                small
                elevation="0"
                ref="focusPetDetailDialog"
                v-on="on"
                @click="handleClick"
                tabindex="0"
                :aria-label="isFamilyPlan ? 'Expand breakdown for Family total' : 'Expand breakdown for Individual total'"
                >
                    Expand
                    <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
            </template>
            <v-container>
                <v-card elevation="3" role="dialog" aria-modal="true" aria-labelledby="petDetailDialogTitle">
                    <v-card-title>
                        <span role="heading" aria-level="2" id="petDetailDialogTitle">Pet Detail View</span>
                        <v-spacer></v-spacer>
                        <v-btn icon
                               color="black"
                               @click="closeMenu"
                               @keydown="handleShiftTabOnModalFirstElement"
                               class="float-right"
                               aria-label="Close Detail View."
                               tabindex="0"
                               ref="showDetailsCloseButton"
                               >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>                        
                        <PetPlanView :pets="pets"
                                     :plans="plans"
                                     :headers="headers"
                                     :isFamilyPlan="isFamilyPlan"
                                     @closeMenu="closeMenu"
                                     @addPet="addPet"
                                     @changePlan="changePlan"
                                     ></PetPlanView>
                    </v-card-text>
                </v-card>
            </v-container>
        </v-menu>
    </div>
</template>
<script>
    import PetPlanView from "./PetPlanView.vue";

    export default {
        name: 'SummaryViewPanel',
        components: {
            PetPlanView
        },
        props: {
            isFamilyPlan: {
                type: Boolean,
                default: false,
            },
            newPet: {
                type: Array
            },
            pets: {
                type: Array
            },
            plans: {
                type: Array
            },
            headers: {
                type: Array
            },
            maxPetView: {
                type: Number
            }
        },
        data: () => (
            {
                fav: true,
                menu: false,
                message: false,
                hints: true

            }),
        methods: {
            handleClick(){
                this.toggleHideBackgroundContianer();
            },
            toggleHideBackgroundContianer(){
                let element=document.getElementById("app").querySelector(".v-application--wrap");
                element.setAttribute("aria-hidden","true");
                console.log("Background dialog container",element);
                let elementsArray = document.querySelectorAll("body>:not(#app)");
                console.log("Array of rest of the elements for hiding-",elementsArray);
                Array.from(elementsArray).forEach(node=>{
                    node.setAttribute("aria-hidden","true");
                })
            },
            toggleShowBackgroundContianer(){
                let element=document.getElementById("app").querySelector(".v-application--wrap");
                element.removeAttribute("aria-hidden");
                console.log("Background dialog container",element);
                let elementsArray = document.querySelectorAll("body>:not(#app)");
                console.log("Array of rest of the elements for showing-",elementsArray);
                Array.from(elementsArray).forEach(node=>{
                    node.removeAttribute("aria-hidden");
                })
            },
            focusPetDetailDialog(){
                this.$refs.focusPetDetailDialog.$el.focus();
            },
            handleShiftTabOnModalFirstElement(e) {
                if(e.shiftKey && e.key === "Tab") {
                    e.preventDefault();
                }
            },
            closeMenu() {
                this.toggleShowBackgroundContianer();
                this.menu = false;
                this.focusPetDetailDialog();
                
            },
            addPet() {
                this.$emit("openPetDialog");
                this.closeMenu();
            },
            changePlan() {
                this.$emit("switchPet");
            },
            focusCloseButton() {
                const interval = setInterval(()=>{
                    if(this.$refs.showDetailsCloseButton) {
                        this.$nextTick(() => this.$refs.showDetailsCloseButton.$el.focus());
                        clearInterval(interval);
                    }
                },50);
            }
        },
        watch: {
            menu:{ 
                    function (menu) {
                        this.menu = menu;

                        if(this.menu) {
                         this.$nextTick(() =>{
                            this.focusCloseButton();
                            })
                        }
                        if(!this.menu) {
                            this.toggleShowBackgroundContianer();
                            this.$nextTick(()=>this.focusPetDetailDialog())
                        }
                    },
                    flush:'post',
                }
        },
        updated() {
            if(this.menu) {
                setTimeout(() => {
                    this.$nextTick(() => this.$refs.showDetailsCloseButton.$el.focus());
                }, 1000);
            }
        }
    }
</script>