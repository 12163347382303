export default {
    // Careful | in cases where the mixin and the component define 
    // the same property, the component's property will take precedence.
    data() {
        return {
            popOverTitle: null,
        }
    },
    computed: {
        currentPetIndex() {
            return this.$store.getters.currentPetIndex;
        },
        selectedPolicies() {
            return this.$store.getters.selectedPolicies;
        },
        isMetGen: function () {
            return this.$store.state.isMetGen;
        },
        routineCareLabel: function () {
            return this.$store.getters.isMetGen ? "Preventive Care" : "Routine Care"
        },
        isFamilyPlan: function () {
            return this.$store.getters.isFamilyPlan;
        },
    }
}