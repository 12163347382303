<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        max-width="350px"
        offset-x
        role="none"
        aria-hidden="true"
        >
        <template v-slot:activator="{ on}">
            <v-btn
            class="pa-0 d-inline popover-trigger"
            elevation="0"
            color="primary"
            x-small
            text
            fab
            v-on="on"
            :aria-label="'Hint: ' + title"
            tabindex="0"
            ref="popoverInvoker"
            @click="handleClick"
            >
                <v-icon>mdi-help-circle</v-icon>
            </v-btn>
        </template>

        <v-card role="dialog" aria-modal="false" :aria-labelledby="'popover-title-'+id" :aria-describedby="'popover-content-'+id">
            <v-card-title :id="'popover-title-'+id">  <v-icon color="primary">mdi-help-circle</v-icon>  {{ title }} </v-card-title>
            <v-divider role="none"></v-divider>
            <v-card-text :id="'popover-content-'+id">
                {{ content }}
            </v-card-text>
            <v-divider role="none"></v-divider>
            <v-card-actions>
            <v-spacer></v-spacer>
                <v-btn
                    text
                    tabindex="0"
                    ref="popoverOkButton"
                    @click="menu = false"
                    @blur="menu = false"
                >
                    Ok
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>
<script>
export default {
    name: 'Popover',
    props: {
        id: String,
        title: String,
        content: String
    },
    data: () => (
    {
      fav: true,
      menu: false,
      message: false,
      hints: true,
    }),
    watch:{
        menu:{
           function(menu){
            this.menu=menu;

            if(this.menu){
                this.$nextTick(()=>{
                    this.focusInside();
                })
            }
            if(!this.menu){
                this.toggleShowBackgroundContianer();
                this.focusInvoker();
            }
           } 
        }
    },
    methods: {
        handleClick(){
            this.toggleHideBackgroundContianer();  
        },
        focusInvoker() {
            this.$nextTick(()=>{
                this.$refs.popoverInvoker.$el.focus()
            });
        },
        focusInside() {
            this.$nextTick(()=>this.$refs.popoverOkButton.$el.focus());
        },
        toggleHideBackgroundContianer(){
                let element=document.getElementById("app").querySelector(".v-application--wrap");
                element.setAttribute("aria-hidden","true");
                console.log("Background dialog container",element);
                let elementsArray = document.querySelectorAll("body>:not(#app)");
                console.log("Array of rest of the elements for hiding-",elementsArray);
                Array.from(elementsArray).forEach(node=>{
                    node.setAttribute("aria-hidden","true");
                })
            },
            toggleShowBackgroundContianer(){
                let element=document.getElementById("app").querySelector(".v-application--wrap");
                element.removeAttribute("aria-hidden");
                console.log("Background dialog container",element);
                let elementsArray = document.querySelectorAll("body>:not(#app)");
                console.log("Array of rest of the elements for showing-",elementsArray);
                Array.from(elementsArray).forEach(node=>{
                    node.removeAttribute("aria-hidden");
                })
            },
    },
    updated() {
        if(!this.menu) {
            const interval = setInterval(()=>{
                if(this.$refs.popoverInvoker) {
                    this.toggleShowBackgroundContianer();
                    this.focusInvoker();
                    clearInterval(interval);
                }
            }, 50);
        }
        if(this.menu) {
            const interval = setInterval(()=>{
                if(this.$refs.popoverOkButton) {
                    this.focusInside();
                    this.toggleHideBackgroundContianer();
                    clearInterval(interval);
                }
            }, 50);
        }
    }
}
</script>