<template>
    <v-item-group class="pricing-grid" :value="currentSelectedPetPlanId" @change="handlePlanSelectChange" :mandatory="currentSelectedPetPlanId != null && !isCustomPlanActive">
        <v-container>
            <v-row no-gutters class="d-flex  align-stretch ma-n2 justify-center" role="region" aria-label="Pricing Plans">
                <span class="d-sr-only" aria-live="polite"> {{cardPricingAnnouncementText}} </span>
                <span class="d-sr-only" aria-hidden="true" id="DiscountAnnouncememt">Before Selecting a plan, ensure that you have scanned the discounts applied section below and Chosen the appropriate discounts for you</span>
                <v-col v-for="(plan, planIndex) in plans"
                       :key="plan.planId"
                       class="mb-4"
                       cols="12"
                       md="4">
                    <v-item rounded v-slot="{ active, toggle }" :value="plan.planId">
                        <v-card
                            height="100%" 
                            :elevation=" active ? 10 : 5"
                            class="price-card d-flex flex-column align-center ma-2"
                            :class="[plan.limit === 5000 ? 'plan-focus' : '', active ? 'v-plan-active' : '' ]"
                            v-bind:style="{ backgroundImage: 'url(' + backgroundPerPlanLimit(plan.limit) + ')' }"
                            role="region"
                            aria-roledescription="card" :aria-labelledby="'card-pricing-title-' + plan.limit"
                        >
                            <v-list-item-content class="col-12 align-start pt-0">
                                <div :id="'card-pricing-title-' + plan.limit" class="plan-focus-heading ml_yellow black--text mb-md-2 text-center pa-1 order-1 order-md-1 show" v-if="plan.limit === 2000" aria-level="3" role="heading">
                                    Most Balanced
                                </div>
                                <div :id="'card-pricing-title-' + plan.limit" class="plan-focus-heading ml_secondarydarkgreen white--text mb-md-2 text-center pa-1 order-1 order-md-1 show" v-if="plan.limit === 5000" aria-level="3" role="heading">
                                    Most Popular
                                </div>
                                <div :id="'card-pricing-title-' + plan.limit" class="plan-focus-heading ml_darkblue white--text mb-md-2 text-center pa-1 order-1 order-md-1 show" v-else-if="plan.limit === 10000" aria-level="3" role="heading">
                                    High Deductible Option
                                </div>
                                <v-list-item-title class="headline mb-4 px-2 py-md-2 text-center order-3 order-md-2">
                                    <v-icon class="mb-4 size-32"> $petPawIcon </v-icon>
                                    <!-- {{formatCurrency(plan.monthlyPremium)}} -->
                                    <span></span>
                                    <div class="pricing-value" :id="'card-monthly-price-'+plan.limit">
                                        <span class="currency">
                                            {{ getPriceSplit(plan.monthlyPremium)[0] }}
                                        </span>
                                        <span class="units">
                                            {{ getPriceSplit(plan.monthlyPremium)[1] }}
                                        </span>
                                        <span class="cents">
                                            {{ getPriceSplit(plan.monthlyPremium)[2] }}
                                        </span>
                                    </div>
                                    <small class="frequency d-block">Monthly Premium</small>
                                </v-list-item-title>
                                <v-list-item-subtitle class="plan-features py-md-1 px-1 order-2 order-md-3">
                                    <v-row class="d-flex flex-wrap justify-center" no-gutters role="list">
                                        <v-col cols="6" sm="3" md="6" lg="6" role="listitem">
                                            <div class="d-flex flex-column">
                                                <div class="feature-name text-center">
                                                    <span>Annual Benefit</span>
                                                </div>
                                                <div class="feature-value text-center"> ${{numberWithCommas(plan.limit)}}</div>
                                            </div>
                                        </v-col>
                                        <v-col cols="6" sm="3" md="6" lg="6" role="listitem">
                                            <div class="d-flex flex-column">
                                                <div class="feature-name text-center">
                                                    <span>Deductible</span>
                                                </div>
                                                <div class="feature-value text-center"> ${{numberWithCommas(plan.deductible)}}</div>
                                            </div>
                                        </v-col>
                                        <v-col cols="6" sm="3" md="6" lg="6" role="listitem">
                                            <div class="d-flex flex-column">
                                                <div class="feature-name text-center">
                                                    <span>Reimbursement</span>
                                                </div>
                                                <div class="feature-value text-center"> {{plan.reimbursements * 100}}%</div>
                                            </div>
                                        </v-col>
                                        <v-col cols="6" sm="3" md="6" lg="6" role="listitem">
                                            <div class="d-flex flex-column">
                                                <div class="feature-name text-center">
                                                    <span>{{routineCareLabel}}</span>
                                                </div>
                                                <div class="feature-value text-center"> {{plan.rider.displayName}}</div>
                                            </div>
                                        </v-col>
                                    </v-row>                                   
                                </v-list-item-subtitle>
                                
                            </v-list-item-content>
                            <v-card-text class="text-center py-0">
                                <v-row>
                                        <v-col cols="12">
                                            <span class="d-sr-only" aria-hidden="true" :id="'definitionBtnFor-' + plan.limit">For </span>
                                            <v-btn :id="'definitionBtn-'+plan.limit" class="btn-text-reset btn-text-underline" href="#TermsDefinitions" @click.stop='goToElementAndSetFocusCallback("TermsDefinitions", "definitionBtn-"+plan.limit )' v-on:keyup.enter='goToElementAndSetFocusCallback("TermsDefinitions", "definitionBtn-"+plan.limit )' color="ml_blue" text :aria-labelledby="'definitionBtnFor-' + plan.limit + ' card-pricing-title-' + plan.limit + ' definitionBtnText-' + plan.limit">
                                                <span :id="'definitionBtnText-' + plan.limit">What Do These Terms <span class="d-sr-only">(Annual Benefit, Deductible, Reimbursement, {{routineCareLabel}})</span> Mean?</span>
                                            </v-btn>
                                        </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn 
                                    aria-describedby="DiscountAnnouncememt" 
                                    text 
                                    class="d-flex align-center btn-text-reset"
                                    @click="toggle" 
                                    :aria-labelledby="'btn-text-selection-state-'+plan.limit+' card-monthly-price-'+plan.limit+' card-pricing-title-' + plan.limit" 
                                    :id="'select-plan-btn-'+plan.planId" 
                                    :ref="'select-plan-btn-'+plan.planId">
                                    <div class="plan-input-radio" aria-hidden="true">
                                        <input class="mr-1" type="radio" :checked="active ? 'checked':''"/>
                                        <label></label>
                                    </div>
                                    
                                    <span class="font-weight-bold" :id="'btn-text-selection-state-'+plan.limit" v-if="active">{{selectedPlanText}}</span>
                                    <span class="font-weight-bold" :id="'btn-text-selection-state-'+plan.limit" v-else >{{selectPlanText}}</span>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-item>
                </v-col>
            </v-row>
        </v-container>
    </v-item-group>

</template>
<script>
    import Popover from './shared/Popover.vue';

    import pricing from './global/js/pricing.js';

    export default {
        mixins: [pricing],
        components: {
            Popover
        },
        props: {
            announcePricingSummary: {
                type: Function
            },
            isCustomPlanActive: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            selectPlanText: 'Select this option',
            selectedPlanText: 'Selected',
            panelFocusBackgroundSVG: require('@/assets/svg/panelBackgroundshape.svg'),
            panelFocusBackgroundSVG2: require('@/assets/svg/panelBackgroundshape2.svg'),
            panelFocusBackgroundSVG3: require('@/assets/svg/panelBackgroundshape3.svg'),
            popOverTitle: null,
            cardPricingAnnouncementText:""
        }),
        computed: {
            currentSelectedPetPlanId() {
                var selectedPolicy = this.selectedPolicies.find(x => x.petArrayIndex == this.currentPetIndex && !x.customPlan);
                return selectedPolicy != undefined ? selectedPolicy.planId : null;
            },
            isMetGen: function () {
                return this.$store.state.isMetGen;
            },
            routineCareLabel: function () {
                return this.$store.getters.isMetGen ? "Preventive Care" : "Routine Care"
            }           
        },
        watch: {
           plans: {
                handler(newValue,oldValue) {

                    this.$nextTick(() => this.exposePricingCardChangesToSR());
                },
                deep:true
            },
            isFamilyPlan: function () {
                this.$store.commit("setCurrentPetIndex", 0);
                this.$store.commit("setSelectedPolicies", []);
                this.$emit("renderSummaryBar");
            },
            currentSelectedPetPlanId: function (petPlanValue) {
                if (petPlanValue == null) {
                    this.$store.commit("setShowRoutineCare", false);
                } else {
                    this.$store.commit("setShowRoutineCare", true);
                }
            },
            plans: function(){
                this.autoSelectPlan();
            }
        },
        created() {
            this.updateRoutineCareLabels();
        },
        methods: {
            autoSelectPlan(){
                const plans = this.plans;
                const policies = this.selectedPolicies;

                if(policies.findIndex(policy => policy.petArrayIndex === this.currentPetIndex) === -1){
                    plans.forEach((plan, index) => {
                        if(plans.length === 3) {
                            if(index === 1) this.selectPlan(plan.planId);
                        }

                        if(plans.length === 1 || plans.length === 2) {
                            if(index === 0) this.selectPlan(plan.planId);
                        }
                    });
                }

                if(this.$store.state.isFamilyPlan) {
                    this.selectPlan(this.$store.state.currentPlans[1].planId);
                }
            },
            exposePricingCardChangesToSR() {
                this.announceCardPricing(true);
                setTimeout(()=> {
                    this.announceCardPricing(false);
                }, 300);
            },
            backgroundPerPlanLimit(limit){
                switch(limit){
                    case 2000:
                        return require('@/assets/svg/panelBackgroundshape.svg');
                    case 5000: 
                        return require('@/assets/svg/panelBackgroundshape2.svg');
                    case 10000:
                        return require('@/assets/svg/panelBackgroundshape3.svg');
                }
            },
            announceCardPricing(shouldAnnounce) {
                if(shouldAnnounce) {
                    let announcementText = "Pricing cards have updated prices. Now, "
                    for(let i = 0; i < this.plans.length; i++) {
                        let plan = this.plans[i];
                        let planTitle = "for ";
                        if(plan.limit === 5000) planTitle += "Most Popular plan";
                        else if(plan.limit === 10000) planTitle += "High Deductible Option";
                        else planTitle += "Most Balanced Plan";
                        let price = "" + this.getPriceSplit(plan.monthlyPremium)[0] + this.getPriceSplit(plan.monthlyPremium)[1] + this.getPriceSplit(plan.monthlyPremium)[2];
                        announcementText += planTitle + ", monthly premium price is : " + price + ". ";
                    }
                    this.cardPricingAnnouncementText= announcementText;
                } else {
                    this.cardPricingAnnouncementText= "";
                }
            },
            handlePlanSelectChange(v) {
                var self = this;
                this.selectPlan(v);
                this.$nextTick(()=>{
                    const interval = setInterval(() => {
                        let btnToFocus = document.getElementById('select-plan-btn-'+v);
                        if(btnToFocus /*&& (self.$store.state.isSms || self.$store.state.noSms)*/) {
                            btnToFocus.focus();
                            this.announcePricingSummary();
                            clearInterval(interval);
                        }
                    }, 50);
                });
            },
            numberWithCommas(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
            formatCurrency(x) {
                var formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                });
                return formatter.format(x);
            },
            getCurrencySymbol(locale, currency) {
                return (0).toLocaleString(
                    locale,
                    {
                        style: 'currency',
                        currency: currency,
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                    }
                ).replace(/\d/g, '').trim()
            },
            updateRoutineCareLabels() {
                this.popOverTitle = `What is ${this.routineCareLabel}?`;
            },
        }
    }
</script>
