export default {
    // Careful | in cases where the mixin and the component define 
    // the same property, the component's property will take precedence.
    computed: {
        plans: function () {
            return this.$store.getters.currentPlans;
        },
        currentPetIndex: function () {
            return this.$store.getters.currentPetIndex;
        },
        viewingFamilyPlan: function () {
            return this.$store.getters.viewingFamilyPlan;
        },
        selectedPolicies() {
            return this.$store.getters.selectedPolicies;
        },
        isFamilyPlan() {
            return this.$store.getters.isFamilyPlan;
        },
    },
    watch: {
        
    },
    methods: {
        selectPlan(planIndex) {
            const plan = this.plans.find(x => x.planId == planIndex);

            if(plan) {

                let selectedPlans = this.selectedPolicies;

                if (this.viewingFamilyPlan) {
                    selectedPlans = [];
                }

                const existingPlan = selectedPlans.findIndex(p => p.petArrayIndex === this.currentPetIndex);

                const selectedPlanData = {
                    "planId": plan.planId,
                    "limit": plan.limit,
                    "deductible": plan.deductible,
                    "reimbursement": plan.reimbursements,
                    "riderId": plan.rider.id,
                    "petArrayIndex": this.currentPetIndex,
                    "customPlan": false,
                    "planDetails": plan
                };

                if (existingPlan !== -1) {
                    this.$store.commit("updateSelectedPolicies", { index: existingPlan, planData: selectedPlanData});
                } else {
                    if(selectedPlans.length < this.currentPetIndex + 1) {
                        const emptySpaceCount = this.currentPetIndex - selectedPlans.length;
                        for (let i = 0; i < emptySpaceCount; i++) {
                            this.$store.commit("addEmptyPolicies");
                        }
                    }
                    this.$store.commit("updateSelectedPolicies", { index: this.currentPetIndex, planData: selectedPlanData });
                }
                
                let isFamilyPlan = false;
                if (this.viewingFamilyPlan) {
                    isFamilyPlan = true;
                }
                this.$store.commit("setIsFamilyPlan", isFamilyPlan);
                this.$emit("renderSummaryBar");
                this.$emit("renderPrincingComponent");
                this.$store.commit("setShowRoutineCare", true);
                this.$emit("setIsCustomPlanActive", false);
            }
        }
    }
}