<template>
    <v-container>
        <div role="region" aria-label="Add-ons">
            <v-card-title class="px-0"><span id="Discount-Title" role="heading" aria-level="2">Add-ons </span></v-card-title>
            <v-card class="v-card-border-gradient">
                <v-card-title class="pa-6">
                    <v-row class="d-flex align-center">
                        <v-col cols="12" md="10">
                            <div class="d-flex align-center flex-wrap flex-md-nowrap">
                                <inline-svg id="routineSvg" :title="routineCareLabel | lowercase" :src="routineSvg" height="50" width="50" class="mr-4 mb-4" role="img"></inline-svg>
                                
                                <span>
                                    <span aria-label="Preventive Care" v-if="isMetGen">
                                        {{MetGenroutineCareHeding}} 
                                        <a  aria-label="Go to Footnote 4 on Routine Care." id="anchorFootnote4"   ref="anchorFootnote4" @click="goToElementAndFocus('footnote-4', 'footnote4')"><sup>[4]</sup></a>  for {{petName}}? 
                                        <Popover :title="popOverTitle" content="Any treatment, service or procedure for the purpose of prevention of injury or illness or for promotion of general health, where there has been no injury or illness."></Popover>                                                                    
                                    </span>
                                    <span aria-label="Routine Care" v-else>
                                        {{routineCareHeding}}
                                        <a aria-label="Go to Footnote 4 on Routine Care." id="anchorFootnote4" ref="anchorFootnote4" @click="goToElementAndFocus('footnote-4', 'footnote4')"><sup>[4]</sup></a> for {{petName}}? 
                                        <Popover :title="popOverTitle" content="Any treatment, service or procedure for the purpose of prevention of injury or illness or for promotion of general health, where there has been no injury or illness."></Popover>                                                                    
                                    </span>
                                    
                                    <p class="body-2 mt-2 mb-0">Get covered for regular care, such as wellness exams, vaccinations, and heartworm prevention medications.</p>

                                </span>
                            </div>
                        </v-col>
                        <v-col cols="12" md="2" class="align-self-center mr-auto ml-auto mr-md-0 ml-md-auto d-flex flex-row align-center justify-center justify-md-end " v-if="isMetGen">
                            <div role="group" aria-labelledby="routineSwitchLabel">
                                <div class="fake_checkbox_wrapper">
                                        <input class="transparent_checkbox" type="checkbox" id="native-checkbox" aria-labelledby="routineSwitchLabel" :checked="metGenPreventiveCare?'checked':null" @change="handleInputClick()"/>
                                    <v-checkbox
                                        ref="RoutineCareRef" 
                                        class="stateIndicators"
                                        v-model="metGenPreventiveCare"
                                        label="YES"
                                        v-on:change="handleCheckboxChange()"
                                        tabindex="-1"
                                        aria-hidden="true"
                                    >
                                        <!-- <template #prepend>
                                            <span class="pt-1 pr-2">
                                            <span aria-hidden="true" class="grey--text text--darken-2" slot="prepend" :aria-label="isMetGen ? 'Do not add preventive care.' : 'Do not include coverage.'">NO</span>
                                            </span>
                                        </template>                
                                        <template #append>
                                            <span class="pt-1 pr-2">
                                                <span aria-hidden="true" class="grey--text text--darken-2" slot="prepend" :aria-label="isMetGen ? 'Add Preventive Care.' : 'Include coverage.'">YES</span>
                                            </span>
                                        </template> -->
                                    </v-checkbox >
                                </div>
                                <label id="routineSwitchLabel" class="d-sr-only" :aria-label="isMetGen ? MetGenroutineCareHeding : routineCareHeding">{{isMetGen ? MetGenroutineCareHeding : routineCareHeding}}</label>
                            </div>
                        </v-col>
                        <v-col v-else cols="6" md="4" class="align-self-end mr-auto ml-auto mr-md-0 ml-md-auto">
                            <v-text-field readonly
                                        :aria-label="routineCareHeding" 
                                        :label="fieldLabel"
                                        :value="routinePlanConfig.sliderValues[routinePlanConfig.routineCareValue]"
                                        :name="fieldName"
                                        @focus="onFocus($event)"
                                        :hint="fieldHint"
                                        persistent-hint></v-text-field>
                        </v-col>
                    </v-row>

                </v-card-title>
                <v-card-text class="py-0">
                    <v-fade-transition mode="in" hide-on-leave>
                        <v-card elevation="2"
                                v-if="fieldName === flat_input_active">
                            <v-card-title class="d-flex justify-space-between p-2">
                                <span class="subtitle-1 font-weight-light">{{cardTitle}}<a id="anchorFootnote4"  ref="anchorFootnote4" @click="goToElementAndFocus('footnote-4', 'footnote4')"><sup>[4]</sup></a> </span>
                                <v-btn icon color="black" v-on:click="closeCardEditor">
                                    <v-icon>mdi-chevron-up</v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-card-text>
                                <v-slider v-model="routinePlanConfig.routineCareValue"
                                        :tick-labels="routinePlanConfig.sliderValues"
                                        :min="routinePlanConfig.minValue"
                                        :max="routinePlanConfig.maxValue"
                                        :step="routinePlanConfig.stepValue"
                                        ticks="always"
                                        :tick-size="routinePlanConfig.sliderValues.length"
                                        thumb-label="true"
                                        :value="routinePlanConfig.routineCareValue"
                                        v-on:change="handleSliderChange()"></v-slider>
                            </v-card-text>

                        </v-card>
                    </v-fade-transition>
                </v-card-text>
            </v-card>
        </div>
    </v-container>
</template>
<script>
    import Popover from './shared/Popover.vue';
    import InlineSvg from 'vue-inline-svg';

    import routineCare from './global/js/routineCare.js';

    export default {
        mixins: [routineCare],
        props: ['routinePlanConfig', 'riderOptions', 'announcePriceCardChange'],
        components: {
            Popover,
            InlineSvg
        },
        data() {
            return {
                routineSvg: require('@/assets/svg/routineCare.svg'),
                routineCareHeding: "Would you like to include coverage for routine care",
                MetGenroutineCareHeding: "Would you like to add Preventive Care for routine checkups",
                cardTitle: "Routine Care Benefit",
                fieldLabel: "Routine Care",
                fieldName: "routinecare-field",
                fieldHint: null,
                flat_input_active: null,
                metGenPreventiveCare: false,
            }
        },
        filters: {
            lowercase(value) {
                return value.toLowerCase();
            }
        },
        created() {
            this.updateRoutineCareLabels();
        }, 
        watch: {
            currentPetIndex:  function () {
                if(!this.isFamilyPlan && this.selectedPolicies[this.currentPetIndex]) {
                    if(this.selectedPolicies[this.currentPetIndex].planDetails.rider?.id === 0) {
                        this.metGenPreventiveCare = false;
                    } else {
                        this.metGenPreventiveCare = true;
                    }
                }
            },
        },
        computed: {
            currentPet() {
                return this.$store.getters.currentPet;
            },
            petName() {
                var pets = this.$store.getters.pets;

                if (pets.length > 1 && this.isFamilyPlan) {
                    let petNameList = this.getPetNameList(pets);
                    let lastPet = petNameList.pop();

                    return petNameList.join(", ") + " and " + lastPet;
                } else {
                    return this.$store.state.currentPet.name;
                }
            },
        },
        methods: {
            onFocus: function (event) {
                event.target.select();
                this.flat_input_active = event.target.name;
            },
            handleInputClick(){
                this.$refs.RoutineCareRef.$el.querySelector("input[type='checkbox']").click();
            },
            getIndex(item) {
                return item.findIndex(i => i.id === 2)
            },
            closeCardEditor: function (event) {
                this.flat_input_active = "";
            },
            handleCheckboxChange() {
                // let checkBoxElement = this.$refs.RoutineCareRef.$el.querySelector("input[type='checkbox']")
                document.getElementById("native-checkbox").focus();

                let riderId = this.riderOptions[0].id;
                if (this.metGenPreventiveCare) riderId = this.riderOptions[1].id;

                this.updateRoutineCare(riderId);
            },
            handleSliderChange() {
                const riderId = this.riderOptions[this.routinePlanConfig.routineCareValue].id;
                this.updateRoutineCare(riderId);
            },
            updateRoutineCare(riderId) {
                this.announcePriceCardChange();
                this.$store.commit("setLoadingNewPrices", true);
                this.$store.dispatch("getRoutineCareFastQuotes", riderId).then(response => {
                    this.$store.commit("setLoadingNewPrices", false);
                    this.$emit("renderSummaryBar");
                    this.$emit("updateRoutineCare", response);
                    this.$store.commit("setDotLoader", false);           
                }).catch((err) => {
                    //TODO: HANDLE ERROR
                });

            },
            updateRoutineCareLabels() {
                this.routineCareHeding = `Would you like to include ${this.isMetGen ? "Preventive" : "Routine"} Checkups`;
                this.cardTitle = `${this.routineCareLabel} Benefit`;
                this.fieldLabel = this.routineCareLabel;
                this.fieldHint = `Select your ${this.routineCareLabel}`;
                this.popOverTitle = `What is ${this.routineCareLabel}?`;
            },
            getPetNameList(petList) {
                var result = [];

                petList.forEach(function (pet) {
                    result.push(pet.name);
                });

                return result;
            },
            resetPreventiveCare() {
                setTimeout(() => {
                    this.metGenPreventiveCare = false;
                }, 500);
            },
            goBackFromCustomize() {
                if(this.$store.state.selectedPolicies[this.currentPetIndex]?.customPlan === false) {
                    if(!this.isFamilyPlan) {
                        if(this.$store.state.selectedPolicies[this.currentPetIndex].planDetails.rider.id === 0) {
                            this.metGenPreventiveCare = false;
                        } else {
                            this.metGenPreventiveCare = true;
                        }
                    } else {
                        if(this.$store.state.familyWithWellness === 0) {
                            this.metGenPreventiveCare = false;
                        } else {
                            this.metGenPreventiveCare = true;
                        }
                    }
                }
            }
        },
    }
</script>
