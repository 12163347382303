<template>
    <v-container>
        <v-row>
            <v-col></v-col>
            <v-col>
                <PhoneDialog></PhoneDialog>
            </v-col>
        </v-row>
        <v-row>
            <SummaryBar :pets="this.petInfo" :plans="this.selectedPlans"></SummaryBar>
        </v-row>
        <PetPlanView :pets="this.petInfo" :plans="this.selectedPlans" :headers="this.planHeaders"></PetPlanView>

    </v-container>
</template>
<script>

import PetView from './shared/pet.vue';
import PetPlanView from './shared/PetPlanView.vue';
import PhoneDialog from './shared/PhoneDialog.vue';
import SummaryBar from './shared/SummaryBar.vue';
import InlineSvg from 'vue-inline-svg';

export default {
    components: {
        PetView,
        InlineSvg, 
        PhoneDialog,
        SummaryBar,
        PetPlanView
    },
    data: () => {
        return{
            array : null,
            petIndex : 3,
            petInfo: [
                {
                    name: 'Chucho', 
                    petType: 'cat',
                    petGenre: 'male',
                    petAge: 2, 
                    petColor: 'Black and white',
                    isPure: false,
                    petBreed: '',
                    petSize: 'small',
                },
                {
                    name: 'Mac', 
                    petType: 'dog',
                    petGenre: 'male',
                    petAge: 3, 
                    petColor: 'Black',
                    isPure: true,
                    petBreed: 'Golden Retriever',
                    petSize: '',
                }
                
            ],
            selectedPlans: [
                {
                    planRate: "$20.45 / mo",
                    firstMonth: "22.55 / mo",
                    annualLimit: "$2000",
                    reimbursement: '80%',
                    deductible: '$250',
                    routineCare: 'None',
                    accidentalCoverageStart: '05/05/2021',
                    illnessCoverageStart: '06/06/2021'
                },
                {
                    planRate: "$25.45 / mo",
                    firstMonth: "22.55 / mo",
                    annualLimit: "$2000",
                    reimbursement: '80%',
                    deductible: '$250',
                    routineCare: 'None',
                    accidentalCoverageStart: '05/05/2021',
                    illnessCoverageStart: '06/06/2021'
                }
            ],
            planHeaders: [
                {
                    planRate: "Plan Rate",
                    firstMonth: "First Month ",
                    annualLimit: "Annual Limit",
                    reimbursement: 'Reimbursement',
                    deductible: 'Deductible',
                    routineCare: 'Routine Care',
                    accidentalCoverageStart: 'Accident Coverage Begins',
                    illnessCoverageStart: 'Illness Coverage Begins'
                }
            ],
        }
    },
    methods: {      
         flattenArray: function(Headers, Plans) {
			let array = [];
			
			Object.keys(Headers[0]).forEach(key => {
                
				let row = { Header: key, Values:[Headers[0][key],{},{},{}]}
				Plans.forEach((element,index) => {
                    row.Values[index + 1] = element[row.Header];
                }); 
				array.push(row);
            });
            this.array = array;

            return array;
        },
        flattenArray2: function(Selected) {
            let array = [{ Header: 'planRate', Values:["Plan Rate",{},{},{}]},
                         { Header: 'firstMonth', Values:["First Month",{},{},{}]},
                         { Header: 'annualLimit', Values:["Annual Limit",{},{},{}]},
                         { Header: 'reimbursement', Values:["Reimbursement",{},{},{}]},
                         { Header: 'deductible', Values:["Deductible",{},{},{}]},
                         { Header: 'routineCare', Values:["Routine Care",{},{},{}]},
                         { Header: 'accidentalCoverageStart', Values:["Accidental Coverage Start",{},{},{}]},
                         { Header: 'illnessCoverageStart', Values:["Illness Coverage Start",{},{},{}]},
            ];
            array.forEach(row => {
                Selected.forEach((element,index) => {
                    row.Values[index + 1] = element[row.Header];
                }); 
            });
            this.array = array;

            return array;
        }
    }
}
</script>